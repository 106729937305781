













































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import CountryForm from './country-form.vue'
import SortingModel from "@/store/entities/sortingmodel";

@Component({
    components: {CountryForm}
})
export default class Country extends AbpBase  {

    pagerequest: PageRequest = new PageRequest();

    async changeSort(data) {
        if (this.pagerequest.sorting == null) {
            this.pagerequest.sorting = [];
        }
        let item = this.pagerequest.sorting.find(x=>x.fieldName === data.key)
        if (item == null){
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pagerequest.sorting.push(sortingModel)
        }
        else{
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getpage()
    }

    edit() {
        this.editModalShow = true;
    }


    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.country.list;
    };

    async handleUpload(file) {
        let formData = new FormData();
        formData.append('file', file);
        await this.$store.dispatch({
            type: 'country/uploadFile',
            data: formData
        })
        await this.getpage();
    }

    get loading() {
        return this.$store.state.country.loading;
    }

    create() {
        this.createModalShow = true;
    }

    async pageChange(page: number) {
        this.$store.commit('country/setCurrentPage', page);
        await this.getpage();
    }

    async search() {
        await this.pageChange(1);
    }

    async pagesizeChange(pagesize: number) {
        this.$store.commit('country/setPageSize', pagesize);
        await this.getpage();
    }

    async getpage() {

        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'country/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.country.pageSize;
    }

    get totalCount() {
        return this.$store.state.country.totalCount;
    }

    get currentPage() {
        return this.$store.state.country.currentPage;
    }

    columns = [{
        title: this.L('Name'),
        key: 'name',
        sortable: 'custom',
    }, {
        title: this.L('Code'),
        key: 'code',
        sortable: 'custom'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 150,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small',
                        disabled: !this.hasEditPermission()
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: () => {
                            this.$store.commit('country/edit', params.row);
                            this.edit();
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        type: 'error',
                        size: 'small',
                        disabled: !this.hasDeletePermission()
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete Country Confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'country/delete',
                                        data: params.row
                                    })
                                    await this.getpage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    async created() {
        await this.getpage();
    }

    hasAddPermission(){
        return this.hasPermission("Pages.Countries.Add");
    }

    hasEditPermission(){
        return this.hasPermission("Pages.Countries.Edit");
    }

    hasDeletePermission(){
        return this.hasPermission("Pages.Countries.Delete");
    }
}
